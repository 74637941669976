import { Children, cloneElement, ComponentProps, isValidElement, JSXElementConstructor, ReactNode } from "react";
import Tooltip from "./Tooltip";
import { IsSnapshotServer } from "../utils/FeaturesHelper";
import clsx from "clsx";
import CustomFilterChip from "./CustomFilterChip";
import MultiSelectByQueryParams from "./MultiSelectByQueryParams";
import { SCALEOPS_COLORS } from "../colors";
import Tab from "./Tab";
import SnapshotIcon from "../Icons/SnapshotIcon";
import { Paper } from "@mui/material";
import TopKMultiLineChart from "../pages/Analytics/AnalyticsV2/Graphs/TopKMultiLineChart";
import TopKMultiDotChart from "../pages/Analytics/AnalyticsV2/Graphs/TopKMultiDotChart";
import RolloutUpdateStrategyBadge from "../pages/Overview/PolicyTuning/RolloutUpdateStrategyBadge";

type WrappedType = "filter" | "tab" | "paper" | "div" | "topK" | "badge";

interface Props {
  children: ReactNode;
  inactive?: boolean;
  wrappedType?: WrappedType;
}

const SnapshotWrapper = ({ children, inactive, wrappedType }: Props) => {
  if (inactive) {
    return <>{children}</>;
  }
  if (!IsSnapshotServer()) {
    return <></>;
  }
  if (!wrappedType) {
    return (
      <div className={"relative border rounded p-3 border-primary-purpleBlue"}>
        {children}
        {getTooltipAndIcon()}
      </div>
    );
  }
  return modifyChildren(children, wrappedType);
};

const modifyChildren = (children: ReactNode, wrappedType?: WrappedType): JSX.Element => {
  let modifiedChildren = children;
  if (wrappedType === "filter") {
    modifiedChildren = Children.map(children, (child) => {
      if (isValidElement(child)) {
        const childProps = child.props as { children: ReactNode };
        child = {
          ...child,
          props: {
            ...childProps,
            children: Children.map(childProps.children, (grandchild) => {
              if (isValidElement(grandchild)) {
                if (grandchild.type === CustomFilterChip) {
                  const props = grandchild.props as ComponentProps<typeof CustomFilterChip>;
                  const newChildren = (
                    <>
                      {props.children}
                      {getTooltipAndIcon(wrappedType, CustomFilterChip)}
                    </>
                  );
                  return cloneElement(grandchild, {
                    ...props,
                    className: clsx(props.className, "relative border-primary-purpleBlue"),
                    children: newChildren,
                  } as typeof props);
                }
                if (grandchild.type === MultiSelectByQueryParams) {
                  const props = grandchild.props as ComponentProps<typeof MultiSelectByQueryParams>;
                  const newChildren = (
                    <>
                      {props.children}
                      {getTooltipAndIcon(wrappedType, MultiSelectByQueryParams)}
                    </>
                  );
                  return cloneElement(grandchild, {
                    ...props,
                    chipSx: { borderColor: SCALEOPS_COLORS.primary.purpleBlue, position: "relative" },
                    children: newChildren,
                  } as typeof props);
                }
              }
              return grandchild;
            }),
          },
        };
      }
      return child;
    });
  }
  if (wrappedType === "tab") {
    modifiedChildren = Children.map(children, (child) => {
      if (isValidElement(child)) {
        if (child.type === Tab) {
          const props = child.props as ComponentProps<typeof Tab>;
          const newChildren = (
            <>
              {props.children}
              {getTooltipAndIcon(wrappedType)}
            </>
          );
          return cloneElement(child, {
            ...props,
            className: clsx(
              props.className,
              "relative border border-b-0 border-primary-purpleBlue hover:border-primary-purpleBlue hover:px-[36px]"
            ),
            children: newChildren,
          } as typeof props);
        }
      }
      return child;
    });
  }
  if (wrappedType === "paper") {
    modifiedChildren = Children.map(children, (child) => {
      if (isValidElement(child)) {
        if (child.type === Paper) {
          const props = child.props as ComponentProps<typeof Paper>;
          const newChildren = (
            <>
              {props.children}
              {getTooltipAndIcon()}
            </>
          ) as ReactNode;
          return cloneElement(child, {
            ...props,
            style: {
              ...props.style,
              borderWidth: 1,
              borderColor: SCALEOPS_COLORS.primary.purpleBlue,
              position: "relative",
            },
            children: newChildren,
          } as typeof props);
        }
        return child;
      }
      return null;
    });
  }
  if (wrappedType === "div") {
    modifiedChildren = Children.map(children, (child) => {
      if (isValidElement(child)) {
        const props = child.props as ComponentProps<"div">;
        const newChildren = (
          <>
            {props.children}
            {getTooltipAndIcon()}
          </>
        );
        return cloneElement(child, {
          ...props,
          className: clsx(props.className, "relative border border-primary-purpleBlue"),
          children: newChildren,
        } as typeof props);
      }
      return child;
    });
  }
  if (wrappedType === "topK") {
    modifiedChildren = Children.map(children, (child) => {
      if (isValidElement(child)) {
        const childProps = child.props as { children: ReactNode };
        child = {
          ...child,
          props: {
            ...childProps,
            children: Children.map(childProps.children, (grandchild) => {
              if (isValidElement(grandchild)) {
                if (grandchild.type === TopKMultiLineChart || grandchild.type === TopKMultiDotChart) {
                  const chartType = grandchild.type === TopKMultiLineChart ? TopKMultiLineChart : TopKMultiDotChart;
                  const props = grandchild.props as ComponentProps<typeof chartType>;
                  const newChildren = (
                    <>
                      {props.children}
                      {getTooltipAndIcon()}
                    </>
                  );
                  return cloneElement(grandchild, {
                    ...props,
                    wrapDivClassName: clsx(props.wrapDivClassName, "relative border-primary-purpleBlue"),
                    children: newChildren,
                  } as typeof props);
                }
              }
              return grandchild;
            }),
          },
        };
      }
      return child;
    });
  }
  if (wrappedType === "badge") {
    modifiedChildren = Children.map(children, (child) => {
      if (isValidElement(child)) {
        const props = child.props as ComponentProps<typeof RolloutUpdateStrategyBadge>;
        const newChildren = (
          <>
            {props.children}
            {getTooltipAndIcon(wrappedType)}
          </>
        );
        return cloneElement(child, {
          ...props,
          className: clsx(props.className, "relative border border-primary-purpleBlue"),
          children: newChildren,
        } as typeof props);
      }
      return child;
    });
  }

  return modifiedChildren as JSX.Element;
};

const getTooltipAndIcon = (wrappedType?: WrappedType, element?: JSXElementConstructor<never>) => {
  return (
    <Tooltip
      title={
        <>
          This feature is <b>only visible in snapshot mode</b>
        </>
      }
      placement="top-start"
      className={"absolute top-0 left-0"}
      maxWidth={500}
    >
      <div
        className={clsx("text-primary-purpleBlue flex translate-y-[-60%] items-center rounded", {
          "px-1 mx-1 bg-white": !wrappedType,
        })}
      >
        {!wrappedType && (
          <>
            <SnapshotIcon height={18} width={18} /> <small className="pl-1">Snapshot visibility</small>
          </>
        )}
        {wrappedType === "filter" && element === CustomFilterChip && (
          <SnapshotIcon height={16} width={16} className="bg-white translate-y-[1px]" />
        )}
        {wrappedType === "filter" && element === MultiSelectByQueryParams && (
          <SnapshotIcon height={16} width={16} className="bg-white translate-x-0.5 translate-y-[2px]" />
        )}
        {wrappedType === "tab" && (
          <SnapshotIcon height={16} width={16} className="translate-y-[10px] translate-x-[1px]" />
        )}
        {wrappedType === "badge" && (
          <SnapshotIcon height={16} width={16} className="bg-white -translate-x-0.5 translate-y-[2px]" />
        )}
      </div>
    </Tooltip>
  );
};
export default SnapshotWrapper;
