import { TypographyVariant } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { GetSingleAnalytics, GetSingleAnalyticsParams, GetSingleAnalyticsResponse } from "../../../api/fetcher";
import Loading from "../../../components/Loading";
import { currencyFormatter, MemoryFormatter, numberFormatter } from "../../../utils/formatterUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../utils/useGetTimeoutSeconds";
import RunningNumberBox from "../../Overview/TopOverviewSection/RunningNumberBox";
import SingleMetricCompareLabel from "./SingleMetricCompareLabel";
import useFilterQueryParams from "./useFilterQueryParams";

const HAS_METRIC_COMPARE_LABEL = false;

export enum Formats {
  "Memory",
  "Currency",
  "Number",
}
interface Props {
  queryParams: GetSingleAnalyticsParams;
  memoryFormat?: boolean;
  title: React.ReactNode;
  format?: Formats;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  thousandSeparator?: boolean;
  headerVariant?: TypographyVariant;
  numberVariant?: TypographyVariant;
  titleFontWeight?: "regular" | "medium" | "bold";
  boxClassName?: string;
  leftChild?: React.ReactNode;
  valueDataTestId?: string;
  positiveNumberClassName?: string;
  negativeNumberClassName?: string;
  defaultNumberClassName?: string;
  hasGreenNegativeTheme?: boolean;
  labelWrapperClassName?: string;
  isMulticluster?: boolean;
}

const SingleMetricBox = ({
  queryParams,
  title,
  format,
  prefix,
  suffix,
  thousandSeparator,
  headerVariant,
  numberVariant,
  titleFontWeight,
  boxClassName,
  leftChild,
  positiveNumberClassName,
  negativeNumberClassName,
  defaultNumberClassName,
  hasGreenNegativeTheme,
  labelWrapperClassName,
  isMulticluster,
}: Props) => {
  const { queryFn, queryKey } = GetSingleAnalytics();
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);
  const NUMBER_REGEX = /[^0-9.]/g;
  const SUFFIX_NUMBER_REGEX = /[^a-zA-Z]/g;
  const filtersQueryParams = useFilterQueryParams();

  const { data, isLoading, error, isError } = useQuery<GetSingleAnalyticsResponse, Error>({
    queryKey: [queryKey, queryParams, filtersQueryParams, isMulticluster ? "multicluster" : undefined],
    queryFn: () =>
      queryFn({
        ...queryParams,
        multiCluster: isMulticluster,
        tags: filtersQueryParams.tags,
        clusters: filtersQueryParams.clusters,
        timeoutSeconds: timeoutSeconds,
      }),
    enabled: isQueryEnabled,
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMulticluster });

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  useEffect(() => {
    if (isMulticluster && !!data) {
      setIsQueryEnabled(false);
    }
  }, [data]);

  useEffect(() => {
    if (isLoading || isError) {
      setIsQueryEnabled(true);
    }
  }, [queryParams, filtersQueryParams, isLoading, isError]);

  if (isLoading) {
    return <Loading hasTitle={false} />;
  }

  if (isError) {
    console.log(error);
  }

  let value = 0;
  let memoryValue;
  let numberValue;
  const memoryFormatter = MemoryFormatter();

  switch (format) {
    case Formats.Memory:
      memoryValue = memoryFormatter.format(data?.value ?? 0);
      value = Number(memoryValue.replace(NUMBER_REGEX, ""));
      suffix = memoryValue.replace(SUFFIX_NUMBER_REGEX, "");
      break;
    case Formats.Currency:
      prefix = "$";
      value = Number(
        currencyFormatter(data?.value ?? 0)
          .replaceAll("$", "")
          .replaceAll(",", "")
      );
      suffix = "";
      break;
    case Formats.Number:
      numberValue = numberFormatter(data?.value ?? 0, true);
      value = Number(String(numberValue).replace(NUMBER_REGEX, ""));
      suffix = String(numberValue).replace(SUFFIX_NUMBER_REGEX, "");
      break;
    default:
      value = data?.value || 0;
  }

  return (
    <div className="relative w-full">
      <RunningNumberBox
        value={isError || !data?.value || value >= 0 ? value : NaN}
        title={title}
        prefix={prefix}
        suffix={suffix}
        thousandSeparator={thousandSeparator}
        numberClassName={
          value < 0 ? negativeNumberClassName : value >= 0 ? positiveNumberClassName : defaultNumberClassName
        }
        headerVariant={headerVariant}
        numberVariant={numberVariant}
        titleFontWeight={titleFontWeight}
        className={boxClassName}
        leftChild={leftChild}
        valueDataTestId={`single-metric-box-${String(queryParams.type)}`}
        showRoundedValue={!suffix}
      />
      <div
        className={clsx(labelWrapperClassName, {
          "absolute top-[30px] minWidth1660:top-[20px] right-[20px]": !labelWrapperClassName,
        })}
      >
        {HAS_METRIC_COMPARE_LABEL && (
          <SingleMetricCompareLabel
            originalParams={queryParams}
            originalValue={data?.value ?? 0}
            hasGreenNegativeTheme={hasGreenNegativeTheme}
            title={title}
          />
        )}
      </div>
    </div>
  );
};

export default SingleMetricBox;
