import { Dayjs } from "dayjs";
import { adjustedDayjs } from "../utils/dateAndTimeUtils";
import { DEFAULT_DATE_TIME_FORMAT } from "../utils/formatterUtils";

interface Props {
  timestamp: string | number | Dayjs;
  timeFormat?: string;
  fontWeight?: number;
}
const ChartTooltipTime = ({ timestamp, timeFormat = DEFAULT_DATE_TIME_FORMAT, fontWeight = 600 }: Props) => {
  if (typeof timestamp === "string") {
    timestamp = timestamp.replaceAll(/\//g, "-");
  }

  return (
    <p style={{ fontWeight }} className="text-[10px]">
      {adjustedDayjs(timestamp).format(timeFormat)}
    </p>
  );
};

export default ChartTooltipTime;
