import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";
import { ObjectParam, useQueryParam } from "use-query-params";
import {
  GetWorkloadAnalytics,
  GetWorkloadAnalyticsParams,
  GetWorkloadAnalyticsResponse,
} from "../../../../api/fetcher";
import ChartTooltipElement from "../../../../components/ChartTooltipElement";
import ChartTooltipTime from "../../../../components/ChartTooltipTime";
import CustomLegend from "../../../../components/CustomLegend";
import Loading from "../../../../components/Loading";
import { getTimeFormatFromEpochAndPeriodInHours } from "../../../../utils/formatterUtils";
import { NO_OUTLINE, TOOLTIP_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
import FreezeTooltipWarning from "../../../Analytics/AnalyticsV2/Graphs/hooks/FreezeTooltipWarning";
import { TooltipTrigger, UpdateActiveTooltips } from "../../../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../../Analytics/AnalyticsV2/Graphs/hooks/utils";
import { POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "../utils";
import { CHART_HEIGHT, WORKLOAD_ANALYTICS_SYNCH_ID } from "./utils";
import { adjustedDayjs } from "../../../../utils/dateAndTimeUtils";

type Element = {
  key: string;
  label: string;
  color: string;
  dataParser?: (value: number) => number | string;
  tooltipValueColor?: string;
  fill?: string;
  dataKey?: string;
  tooltip?: string;
};
interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    value: string | number;
    name?: string;
    dataKey: string;
    stroke: string;
    payload: Record<string, string | number | undefined>;
  }[];
  label?: string;
  selectedChartComponents: string[];
  elements: Element[];
  yAxisTickFormatter?: ((value: number) => string) | undefined;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  tooltipId?: string;
  updateActiveTooltips?: UpdateActiveTooltips;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  selectedChartComponents,
  yAxisTickFormatter,
  tooltipTrigger,
  frozenTooltipType,
  tooltipId,
  updateActiveTooltips,
}: CustomTooltipProps) => {
  useEffect(() => {
    updateActiveTooltips && active && updateActiveTooltips(String(tooltipId), true);

    return () => {
      updateActiveTooltips && updateActiveTooltips(String(tooltipId), false);
    };
  }, [active, updateActiveTooltips]);

  if (active && payload && payload.length) {
    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        {label && <ChartTooltipTime timestamp={label} />}
        {payload.reverse().map((item, index) => {
          if (selectedChartComponents.includes(item.dataKey ?? "")) {
            let value = item?.payload[item?.dataKey];
            value = yAxisTickFormatter ? yAxisTickFormatter(Number(value)) : value;
            return <ChartTooltipElement key={index} color={item?.stroke ?? ""} value={value} label={item.name} />;
          }
        })}
        <FreezeTooltipWarning tooltipTrigger={tooltipTrigger} frozenTooltipType={frozenTooltipType} />
      </div>
    );
  }

  return null;
};

interface Props {
  title: string;
  elements: Element[];
  queryParams: GetWorkloadAnalyticsParams;
  wrapDivClassName?: string;
  YAxisDomain?: AxisDomain;
  xAxisInterval?: number;
  yAxisTickFormatter?: ((value: number) => string) | undefined;
  onLoadSelectedChartComponents?: string[];
  setHasData?: React.Dispatch<React.SetStateAction<boolean>>;
  showLegentTooltip?: boolean;
  tooltipTrigger?: TooltipTrigger;
  disabledZoom?: boolean;
  frozenTooltipType?: FrozenTooltipType;
  updateActiveTooltips?: UpdateActiveTooltips;
  YAxisAllowDecimals?: boolean;
}

const MultipleLinesChart = ({
  title,
  elements,
  queryParams,
  wrapDivClassName,
  YAxisDomain,
  xAxisInterval,
  yAxisTickFormatter,
  onLoadSelectedChartComponents,
  setHasData,
  showLegentTooltip,
  tooltipTrigger,
  disabledZoom,
  frozenTooltipType,
  updateActiveTooltips,
  YAxisAllowDecimals,
}: Props) => {
  const { queryFn, queryKey } = GetWorkloadAnalytics();
  const [, setDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [selectedViewPeriod, setSelectedViewPeriod] = useViewPeriodQueryParams();
  let chartComponents = Object.values(elements.map((element) => element.key));
  const [selectedChartComponents, setSelectedChartComponents] = useState<string[]>(
    onLoadSelectedChartComponents ?? chartComponents
  );

  const legendComponentStyle: { [key: string]: { color: string } } = {};

  elements.forEach((element) => {
    legendComponentStyle[element.key] = {
      color: element.color,
    };
  });

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  const { data, isLoading, error, isError } = useQuery<GetWorkloadAnalyticsResponse, Error>({
    queryKey: [
      queryKey,
      queryParams.uniqueQueryKey,
      queryParams.from,
      queryParams.to,
      queryParams.name,
      queryParams.namespace,
      queryParams.workloadType,
      queryParams.types,
      queryParams.container,
      queryParams.topk,
    ],
    queryFn: () => queryFn(queryParams),
  });

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to && !disabledZoom) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDates({ from: String(from), to: String(to) });
      if (from && to && setSelectedViewPeriod) {
        setSelectedViewPeriod(String(Math.round((to - from) / 60 / 60)));
      }
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  useEffect(() => {
    if (setHasData && !isLoading && data) {
      setHasData(!!data?.values?.length);
    }
  }, [data, setHasData]);

  if (isLoading) {
    return (
      <div className={clsx(wrapDivClassName, CHART_HEIGHT, "px-4 py-16 bg-white border border-border rounded")}>
        <Loading hasTitle={false} hasFullWrapper />
      </div>
    );
  }

  if (isError) {
    console.log(error);
    return null;
  }

  const graphData:
    | {
        timestamp?: string | undefined;
        values?:
          | {
              [key: string]: number | undefined;
            }
          | undefined;
      }[]
    | undefined = data.values
    ? data.values.map((item) => ({
        timestamp: item.timestamp,
        ...(item.values || {}),
      }))
    : [];

  const actualElementsInData = graphData.reduce((acc: string[], curr) => {
    return [...acc, ...Object.keys(curr)];
  }, []);
  chartComponents = chartComponents.filter((component) => actualElementsInData.includes(component));

  const firstXPointString = String(graphData[0]?.timestamp);
  const firstXPointEpoch = adjustedDayjs(firstXPointString).unix();
  const lastXPointString = String(graphData[graphData.length - 1]?.timestamp);
  const lastXPointEpoch = adjustedDayjs(lastXPointString).unix();

  const maxGraphDataValue = Math.max(
    ...graphData
      .map((item) => Object.values(item))
      .flat()
      .map((item) => (typeof item === "number" ? item : 0))
  );

  const yTickCount = Math.ceil(maxGraphDataValue) + 2;
  const hasData = graphData && graphData.length > 0;

  return (
    <div
      className={clsx(
        wrapDivClassName,
        CHART_HEIGHT,
        "px-4 pt-8 pb-16 bg-white border border-border rounded unselectable-svg-text relative"
      )}
    >
      {!hasData && (
        <div className="absolute w-full mt-[90px] ml-[-16px] flex justify-center align-center">
          <Typography className="border border-border rounded px-4 py-2" variant="caption">
            No data
          </Typography>
        </div>
      )}
      <Typography variant="body2" className="w-full text-center" style={{ fontWeight: 600 }}>
        {title}
      </Typography>
      <ResponsiveContainer
        width="100%"
        height="100%"
        className={clsx("pt-[20px]", {
          "opacity-20": !hasData,
        })}
      >
        <ComposedChart
          data={graphData}
          syncId={WORKLOAD_ANALYTICS_SYNCH_ID}
          onMouseDown={(e) => {
            e.activeLabel &&
              !disabledZoom &&
              setSelectPosition({
                ...selectPosition,
                from: adjustedDayjs(e.activeLabel).unix(),
                fromX: e.activeLabel,
              });
          }}
          onMouseMove={(e) => {
            selectPosition?.from &&
              !disabledZoom &&
              setSelectPosition({
                ...selectPosition,
                to: adjustedDayjs(e.activeLabel).unix(),
                toX: e.activeLabel,
              });
          }}
          onMouseLeave={() => {
            if (selectPosition?.from && selectPosition?.to && !disabledZoom) {
              if (selectPosition?.from < selectPosition?.to) {
                setSelectPosition({
                  ...selectPosition,
                  to: lastXPointEpoch,
                  toX: lastXPointString,
                });
              } else {
                setSelectPosition({
                  to: selectPosition.from,
                  toX: selectPosition.fromX,
                  from: firstXPointEpoch,
                  fromX: firstXPointString,
                });
              }
            }
          }}
        >
          <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
          <Tooltip
            wrapperStyle={NO_OUTLINE}
            content={
              <CustomTooltip
                selectedChartComponents={selectedChartComponents}
                elements={elements}
                yAxisTickFormatter={yAxisTickFormatter}
                tooltipTrigger={tooltipTrigger}
                frozenTooltipType={frozenTooltipType}
                tooltipId={queryKey}
                updateActiveTooltips={updateActiveTooltips}
              />
            }
            trigger={tooltipTrigger}
          />
          {elements.map((element) => {
            const elementData = elements.find((e) => e.key === element.key);
            if (!elementData || !selectedChartComponents.includes(elementData.key)) return null;
            const { key, label, color, fill } = elementData;
            return (
              <Area
                type="linear"
                strokeWidth={2}
                dataKey={key}
                name={label}
                stroke={color}
                fill={fill}
                fillOpacity={fill ? 0.4 : 0}
              />
            );
          })}
          {selectPosition?.fromX && selectPosition?.toX ? (
            <ReferenceArea
              x1={selectPosition?.fromX}
              x2={selectPosition?.toX}
              stroke="#3B8BFF"
              fill="#3B8BFF"
              fillOpacity={0.3}
              strokeOpacity={0.3}
            />
          ) : null}
          <XAxis
            dataKey="timestamp"
            interval={Math.floor(graphData.length / (xAxisInterval ?? 5))}
            strokeWidth={2}
            style={{ fontSize: "x-small" }}
            tickFormatter={(value) => {
              const epochValue = dayjs(String(value)).unix();
              return getTimeFormatFromEpochAndPeriodInHours(epochValue, selectedViewPeriod);
            }}
          />
          <YAxis
            style={{ fontSize: "x-small" }}
            domain={YAxisDomain ? YAxisDomain : [0, Math.ceil((maxGraphDataValue ? maxGraphDataValue : 1) * 1.2)]}
            tickCount={!hasData || yAxisTickFormatter ? undefined : yTickCount}
            tickFormatter={maxGraphDataValue ? (hasData ? yAxisTickFormatter : undefined) : () => ""}
            tickLine={!!maxGraphDataValue}
            strokeWidth={2}
            allowDecimals={YAxisAllowDecimals}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <div className={clsx("w-full pt-4", { "opacity-20": !hasData })}>
        <CustomLegend<string>
          selectedChartComponents={selectedChartComponents}
          setSelectedChartComponents={setSelectedChartComponents}
          componentStyle={legendComponentStyle}
          ChartComponents={chartComponents
            .map((component) => ({
              [component]: component,
            }))
            .reduce((a, b) => ({ ...a, ...b }), {})}
          renderNameFunction={(key) => {
            const element = elements.find((e) => e.key === key);
            return element ? element.label : "";
          }}
          customElementTooltip={
            showLegentTooltip
              ? (key) => {
                  const element = elements.find((e) => e.key === key);
                  return element ? element?.tooltip : "";
                }
              : undefined
          }
          className="-mt-1"
          fontWeight={500}
        />
      </div>
    </div>
  );
};

export default MultipleLinesChart;
