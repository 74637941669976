import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import { memo, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ObjectParam, useQueryParam } from "use-query-params";
import { getPolicyTuningHpaGraphData, GetPolicyTuningHpaGraphDataResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import DefaultFallback from "../../../components/DefaultFallback";
import UsageHpaChart, { HpaChartComponent, HpaGraphDataType } from "./UsageChart/UsageHpaChart";
import { getEndDate, POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "./utils";

import { enableFilterByUrlParam, FilterByUrlParam } from "../../../utils/queryParams";
import { FeatureEnabled } from "../../../utils/FeaturesHelper";
import { adjustedDayjs } from "../../../utils/dateAndTimeUtils";

dayjs.extend(dayjsPluginUTC);

export const getParseGraphData = (data: GetPolicyTuningHpaGraphDataResponse): HpaGraphDataType | undefined => {
  const rawData = data.workloadOverviewHpaGraphDataPoints;

  return rawData?.map((dataPoint) => {
    const { triggerValue, predictionReplicas, predictionWithHeadroom, ...remainingDataPoint } = dataPoint;
    return {
      ...triggerValue,
      ...predictionReplicas,
      ...predictionWithHeadroom,
      ...remainingDataPoint,
      timestamp: adjustedDayjs(dataPoint.timestamp)?.unix(),
    };
  });
};

export const getGraphDataKeys = (
  data: GetPolicyTuningHpaGraphDataResponse,
  showExtendedData: boolean
): HpaChartComponent[] => {
  if (!data.workloadOverviewHpaGraphDataPoints) {
    return [];
  }

  const initAccumulator: HpaChartComponent[] = showExtendedData
    ? [
        "currentReplicas",
        "recommendedReplicas",
        "originalReplicas",
        "currentMinReplicas",
        "recommendedMinReplicas",
        "recommendedMinReplicasP60",
        "recommendedMinReplicasPercentile",
        "maxValueRecordedReplicas",
        "originalMinReplicas",
      ]
    : [
        "currentReplicas",
        "recommendedReplicas",
        "originalReplicas",
        "currentMinReplicas",
        "recommendedMinReplicas",
        "originalMinReplicas",
      ];

  return data.workloadOverviewHpaGraphDataPoints?.reduce<HpaChartComponent[]>((acc, dataPoint) => {
    return Array.from(
      new Set([
        ...(Object.keys(dataPoint.triggerValue ?? {}) as HpaChartComponent[]),
        ...(showExtendedData ? (Object.keys(dataPoint.predictionReplicas ?? {}) as HpaChartComponent[]) : []),
        ...(showExtendedData ? (Object.keys(dataPoint.predictionWithHeadroom ?? {}) as HpaChartComponent[]) : []),
        ...acc,
      ])
    );
  }, initAccumulator);
};

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  policyName: string | undefined;
  selectedChartComponents: HpaChartComponent[];
  wrapperHeight?: number;
  setSelectedChartComponents: (selectedChartComponents: HpaChartComponent[]) => void;
  setHpaChartComponents: (hpaChartComponents: Record<string, HpaChartComponent>) => void;
}

const ReplicasChart = memo(
  ({
    selectedWorkload,
    policyName,
    selectedChartComponents,
    wrapperHeight,
    setSelectedChartComponents,
    setHpaChartComponents,
  }: Props) => {
    const policyTuningHpa = getPolicyTuningHpaGraphData();
    const namespace = selectedWorkload.namespace;
    const name = `${selectedWorkload.type.toLocaleLowerCase()}-${selectedWorkload.workloadName}`;
    const [urlDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
    const [selectedViewPeriod] = useViewPeriodQueryParams();
    const showExtendedData = enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_RECOMMENDATION);
    const isDemoVersion = FeatureEnabled("DemoVersion");

    const [replicasData, setReplicasData] = useState<HpaGraphDataType>([]);

    const { data, isLoading, isFetching, failureCount } = useQuery<GetPolicyTuningHpaGraphDataResponse, Error>(
      [policyTuningHpa.queryKey, name, namespace, policyName, selectedViewPeriod, urlDates?.from, urlDates?.to],
      () => {
        return policyTuningHpa.queryFn({
          name,
          namespace,
          policyName,
          startDate: urlDates?.from
            ? dayjs(Number(urlDates?.from) * 1000)
                .utc()
                .format("YYYY-MM-DDTHH:mm:00")
            : dayjs.utc(getEndDate()).subtract(Number(selectedViewPeriod), "hour").utc().format("YYYY-MM-DDTHH:mm:00"),
          endDate: urlDates?.to
            ? dayjs(Number(urlDates?.to) * 1000)
                .utc()
                .format("YYYY-MM-DDTHH:mm:00")
            : getEndDate(),
        });
      },
      {
        retry: 30,
        refetchOnWindowFocus: false,
      }
    );

    useEffect(() => {
      if (data) {
        const rawData = data;
        delete rawData.firstDataPointTimestamp;
        const replicasParsedData = getParseGraphData(rawData);
        const keys = getGraphDataKeys(rawData, showExtendedData);
        if (keys.length > 0 && replicasParsedData) {
          setReplicasData(replicasParsedData);
          setHpaChartComponents(Object.fromEntries(keys.map((item) => [item, item])));
          setSelectedChartComponents(
            keys.filter(
              (item) =>
                item === "currentReplicas" ||
                item === "recommendedReplicas" ||
                (isDemoVersion && item === "originalReplicas") ||
                item.includes("triggerBasedReplicas:") ||
                item.includes("predictionBasedReplicas:") ||
                item.includes("predictionBasedReplicasWithHeadroom:")
            )
          );
        }
      }
    }, [data]);

    const showLoadingOnGraph = isLoading || isFetching;

    const hasNoData = !replicasData || (replicasData && replicasData.length === 0);

    return (
      <div className="pr-[35px]">
        {hasNoData && failureCount > 5 && (
          <div className="w-full p-4">
            <Typography variant="body2">Failed to load data</Typography>
          </div>
        )}
        <div className="flex gap-8">
          <ErrorBoundary
            fallback={<DefaultFallback message="Failed to load Replicas Chart. Please check your setup." />}
          >
            <UsageHpaChart
              title="Replicas"
              selectedChartComponents={selectedChartComponents}
              data={replicasData}
              isLoading={showLoadingOnGraph}
              wrapperHeight={wrapperHeight}
              minMaxYAxisDomainValue={0.6}
            />
          </ErrorBoundary>
        </div>
      </div>
    );
  }
);

export default ReplicasChart;
