import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { GetNetworkCostEnabled, GetNetworkCostEnabledResponse } from "../../api/fetcher";
import AlertBar, { AlertBarTheme } from "../../components/AlertBar";
import CodeSnippet, { THEME } from "../../components/CodeSnippet";
import NetworkIcon from "../../Icons/NetworkIcon";
import { useMainContext } from "../../MainContext";
import useGetHealthCheck from "../../utils/useGetHealthCheck";

const { queryFn: getNetworkCostEnabledQueryFn, queryKey: getNetworkCostEnabledQueryKey } = GetNetworkCostEnabled();

const NetworkAlerts = () => {
  const { data } = useGetHealthCheck();
  const { currentCluster } = useMainContext();

  const {
    data: getNetworkCostEnabledData,
    isLoading,
    isError,
  } = useQuery<GetNetworkCostEnabledResponse, Error>({
    queryFn: () =>
      getNetworkCostEnabledQueryFn({
        multiCluster: true,
      }),
    queryKey: [getNetworkCostEnabledQueryKey],
    enabled: !!currentCluster,
  });

  const isNetworkCostEnabledForCurrentCluster =
    !!currentCluster && !!getNetworkCostEnabledData?.networkCostEnabled?.[currentCluster];

  const areThereOtherDisabledClusters = Object.keys(getNetworkCostEnabledData?.networkCostEnabled || {}).some(
    (cluster) => !getNetworkCostEnabledData?.networkCostEnabled?.[cluster] && cluster !== currentCluster
  );

  if (isLoading || isError) {
    return null;
  }

  return (
    <>
      {!isNetworkCostEnabledForCurrentCluster && (
        <div className="flex flex-col gap-3">
          <div className="flex gap-2 items-center">
            <NetworkIcon width={40} height={40} />
            <div>
              <Typography variant="body1" fontWeight="bold">
                Enable Network Report
              </Typography>
              <Typography variant="body2">
                The Network Report feature is currently disabled.
                {areThereOtherDisabledClusters && (
                  <>
                    <b>Enable the networkMonitor</b> on all connected clusters for <b>full visibility</b>.
                  </>
                )}
              </Typography>
            </div>
          </div>
          <div>
            <Typography variant="caption">
              In order to enable it please <b>add the following to the installation command</b>:
            </Typography>
            <CodeSnippet
              codeSnippet={`
helm repo update scaleops
helm show crds scaleops/scaleops | kubectl replace -f -
helm get values scaleops -n scaleops-system -oyaml | \\
helm upgrade scaleops scaleops/scaleops -n scaleops-system --set global.enableNetworkMonitoring=true -f -
`}
              theme={THEME.light}
              className="w-fit"
            />
            <span className="text-[10px] italic text-text-darkGray">
              Note: This operation will <b>install a DaemonSet</b>.
            </span>
          </div>
        </div>
      )}
      {data?.networkDaemon?.healthy === false && isNetworkCostEnabledForCurrentCluster && (
        <AlertBar
          title={"Network health check failed"}
          customDescription={
            data?.networkDaemon?.reason ||
            "There was an issue with the network health check. Please verify the network status."
          }
          theme={AlertBarTheme.LightRed}
          // hideCloseButton
        />
      )}
    </>
  );
};

export default NetworkAlerts;
