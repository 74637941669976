import { Typography } from "@mui/material";
import clsx from "clsx";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Tooltip from "../components/Tooltip";
import HeartIcon from "../Icons/HeartIcon";
import { useMainContext } from "../MainContext";
import useGetHealthCheck from "../utils/useGetHealthCheck";

const ICON_SIZE = 12;
const LINK_BASE = "/dashboards/insight";

enum HealthStatus {
  AdmissionController = "Admission controller",
  AgentController = "Agent controller",
  Prometheus = "Prometheus",
  Recommender = "Recommender",
  Updater = "Updater",
  KubeStateMetrics = "Kube state metrics",
  ImageMatchesChartVersion = "Image matches chart version",
}

const FailedHealthCheckList = ({ unHealthArrayValues }: { unHealthArrayValues: HealthStatus[] }) => {
  return (
    <div className="flex flex-col gap-1">
      <Typography variant="caption">
        The following health <b>{pluralize("check", unHealthArrayValues.length)}</b> failed:
      </Typography>
      <ul className="list-disc list-inside ml-2">
        {unHealthArrayValues.map((healthStatus) => (
          <li key={healthStatus}>{healthStatus}</li>
        ))}
      </ul>
    </div>
  );
};

const IMAGE_MATCH_CHART_ENABLED = true;

const HealthCheckStatus = () => {
  const { currentCluster } = useMainContext();
  const location = useLocation();

  const [unHealthArray, setUnHealthArray] = useState<HealthStatus[]>([]);
  const [didHealthCheckPassed, setDidHealthCheckPassed] = useState<boolean | undefined>(undefined);

  const { data, isError, isLoading } = useGetHealthCheck();

  useEffect(() => {
    if (data) {
      const unHealthArrayValues: HealthStatus[] = [];
      if (data.admissionController?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.AdmissionController);
      }
      if (data.agentController?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.AgentController);
      }
      if (data.prometheus?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.Prometheus);
      }
      if (data.recommender?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.Recommender);
      }
      if (data.updater?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.Updater);
      }
      if (data.kubeStateMetrics?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.KubeStateMetrics);
      }
      if (IMAGE_MATCH_CHART_ENABLED && !data.imageMatchesChart) {
        unHealthArrayValues.push(HealthStatus.ImageMatchesChartVersion);
      }

      setUnHealthArray(unHealthArrayValues);
      setDidHealthCheckPassed(unHealthArrayValues.length === 0 || data.isFreshInstall);
    }
  }, [data]);

  if (isError) {
    console.log("Error fetching health status");
  }

  if (isLoading || isError || !data) {
    return null;
  }

  let tooltipContent;

  switch (true) {
    case didHealthCheckPassed:
      tooltipContent = (
        <>
          All health checks <b>passed successfully</b>.
        </>
      );
      break;
    case data.isFreshInstall:
      tooltipContent = (
        <p>
          <div className="flex items-center gap-4">
            <b className="text-guideline-darkGreen">Installing ScaleOps</b>
            <div>
              <div className="dot-elastic" />
            </div>
          </div>
          <span>Health checks will be available soon</span>.
        </p>
      );
      break;
    default:
      tooltipContent = <FailedHealthCheckList unHealthArrayValues={unHealthArray} />;
  }

  const link = `${LINK_BASE}?currentClusterURLParam=${currentCluster ?? ""}&selectedViewProfile=Health`;
  const isPathNameIncludesLinkBase = location.pathname.includes(LINK_BASE);

  return (
    <NavLink
      to={link}
      onClick={(e) => {
        isPathNameIncludesLinkBase && e.preventDefault();
      }}
      className="bg-none border-[0.5px] border-white w-fit rounded-lg flex items-center justify-end"
      style={{
        height: "1.5rem",
      }}
    >
      <Tooltip title={tooltipContent} maxWidth={500} placement="right">
        <div
          className={clsx("flex items-center gap-1.5 w-fit px-4", {
            "cursor-default": isPathNameIncludesLinkBase,
            "hover:bg-mainMenuBackgroundHover rounded-lg": !isPathNameIncludesLinkBase,
          })}
          style={{
            transition: "padding 0.3s",
          }}
        >
          <HeartIcon
            width={ICON_SIZE}
            height={ICON_SIZE}
            className={clsx({
              "text-main-green": didHealthCheckPassed,
              "text-main-red animate-pulse": !didHealthCheckPassed,
            })}
          />
          <Typography variant="caption">{didHealthCheckPassed ? "Health check" : "Health check failed"}</Typography>
        </div>
      </Tooltip>
    </NavLink>
  );
};

export default HealthCheckStatus;
