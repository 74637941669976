import BooleanQueryParamFilter from "../../Filters/BooleanQueryParamFilter";
import MultiSelectByQueryParamsFilter from "../../Filters/MultiSelectByQueryParamsFilter";
import SearchByQueryParamsFilter from "../../Filters/SearchByQueryParamsFilter";

export enum Filter {
  Search = "Search title",
  Automated = "Is auto title",
  TestFilter = "Test Filter title",
}

export const defaultFilters = [Filter.Search];

const getOnCloseIconClickFnc = (setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>, filter: Filter) => {
  return () => {
    setSelectedFilters((prev) => prev.filter((f) => f !== filter));
  };
};

export const useGetAllFilters = (setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>) => [
  {
    id: Filter.Search,
    component: <SearchByQueryParamsFilter queryName="searchTerms" />,
  },
  {
    id: Filter.Automated,
    component: (
      <BooleanQueryParamFilter
        label="automated"
        queryName="isAutomated"
        onCloseIconClick={getOnCloseIconClickFnc(setSelectedFilters, Filter.Automated)}
        defaultValue={true}
      />
    ),
  },
  {
    id: Filter.TestFilter,
    component: (
      <MultiSelectByQueryParamsFilter
        name="testFilter"
        options={[
          "very-very-very-very-very-very-very-very-very-very-very-very-very-very-very-very-very-very-very-very-long-option",
          "Option 1",
          "Option 2",
          "Option 3",
          "Option 4",
          "Option 5",
          "Option 6",
          "Option 7",
        ]}
        queryKey="testFilter"
        openOnInitialRender
        onCloseIconClick={getOnCloseIconClickFnc(setSelectedFilters, Filter.TestFilter)}
      />
    ),
  },
];
