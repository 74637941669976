import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import { roundToTwoDecimal } from "../../autoscalers/nodesCostUtils";
import Tooltip from "../../Tooltip";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import {
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WorkloadOverviewTooltipMessage,
} from "./GridColumnsUtils";

export default function PredictionErrorScoreColumn({
  params,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
}) {
  if (isUnallocatedRow(params.row)) return <div></div>;
  return (
    <div className="flex relative">
      <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
        <Tooltip
          title={WorkloadOverviewTooltipMessage(params.row.namespace, params.row.workloadName, params.row.type)}
          maxWidth={500}
          className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
          enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
        >
          {params.row.predictionErrorScore
            ? params.row.predictionErrorScore >= 0
              ? `${roundToTwoDecimal(params.row.predictionErrorScore * 100)}%`
              : "Unpredictable"
            : "N/A"}
        </Tooltip>
      </Typography>
    </div>
  );
}
