import { DataGrid } from "@mui/x-data-grid";
//import { GetUnevictableData } from "../../api/fetcher";
import { getDataGridSx, HEADER_HEIGHT } from "../../utils/styleUtils";
import TopSection from "./TopSection";

//const { queryFn, queryKey } = GetUnevictableData();

export default function Unevictable() {
  return (
    <>
      <div className="p-6">
        <TopSection />
      </div>
      <div className="flex px-8">Charts (TODO)</div>
      <div className="p-8">
        <DataGrid
          headerHeight={HEADER_HEIGHT}
          rowHeight={90}
          sx={{
            ...getDataGridSx(),
          }}
          key={"id"}
          hideFooter={true}
          columns={[]}
          rows={[]}
          style={{ height: "100%" }}
          hideFooterPagination={true}
          autoHeight={true}
          disableSelectionOnClick
          loading={false}
        />
      </div>
    </>
  );
}
