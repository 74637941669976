import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import { components } from "../../../api/schema";
import * as formatterUtils from "../../../utils/formatterUtils";
import Tooltip from "../../Tooltip";
import HPASavingsAvailable from "../HPASavingsAvailable";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import useHpaOptimizationEnabled from "../useHpaOptimizationEnabled";
import {
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WorkloadOverviewTooltipMessage,
} from "./GridColumnsUtils";

export interface SavingsAvailableColumnProps {
  hpaOptimizationEnabled: boolean | undefined;
}

export default function SavingsAvailableColumn({
  params,
  hpaOptimizationEnabled,
}: {
  params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>;
} & SavingsAvailableColumnProps) {
  const hasHPAEnabled = useHpaOptimizationEnabled();

  if (isUnallocatedRow(params.row)) return <div></div>;
  if (params.row.isReadyRecommendation == false) {
    return (
      <Typography fontWeight={410} fontSize={"small"} fontFamily={"Poppins"} fontStyle={"oblique"}>
        inactive
      </Typography>
    );
  }

  const hasHPA = !!params.row.hasHpa && !!params.row.recommendedReplicas && !!params.row.isReadyRecommendation;

  let hapSavingsAvailableValue: number =
    !Number.isNaN(params.row.overallAvailableSavings) && !Number.isNaN(params.row.savingsAvailable)
      ? Number(params.row.overallAvailableSavings) - Number(params.row.savingsAvailable)
      : 0;

  hapSavingsAvailableValue = hapSavingsAvailableValue < 0 ? 0 : hapSavingsAvailableValue;

  const hapSavingsAvailable = formatterUtils.currencyFormatter(hapSavingsAvailableValue);

  const rightSizeAvailableSavings =
    params.row.savingsAvailable == undefined || (params.row.savingsAvailable < 0 && params.row.savingsAvailable > -1)
      ? formatterUtils.currencyFormatter(0)
      : formatterUtils.currencyFormatter(params.row.savingsAvailable);

  const overallAvailableSavings =
    params.row.overallAvailableSavings == undefined
      ? rightSizeAvailableSavings
      : params.row.overallAvailableSavings < 0 && params.row.overallAvailableSavings > -1
      ? formatterUtils.currencyFormatter(0)
      : formatterUtils.currencyFormatter(params.row.overallAvailableSavings);

  const displayValue = hpaOptimizationEnabled ? overallAvailableSavings : rightSizeAvailableSavings;
  const classNameCondition = hpaOptimizationEnabled
    ? displayValue !== "$0" && params.row.overallAvailableSavings && params.row.overallAvailableSavings > -1
    : displayValue !== "$0" && params.row.savingsAvailable && params.row.savingsAvailable > -1;

  const isPolicyProductionHPA = !!params.row.scaleOutAuto;

  if (hasHPA && hapSavingsAvailableValue && hasHPAEnabled) {
    return (
      <HPASavingsAvailable
        hasPolicyWithHpa={isPolicyProductionHPA && hasHPA && hasHPAEnabled}
        rightSizingSavings={rightSizeAvailableSavings}
        HPASavings={hapSavingsAvailable}
        overallSavings={overallAvailableSavings}
      />
    );
  }

  return (
    <Typography
      variant="body2"
      className={clsx("fullCellTooltipContent relative", {
        "text-guideline-darkGreen": classNameCondition,
      })}
      fontWeight={500}
    >
      <Tooltip
        title={WorkloadOverviewTooltipMessage(params.row.namespace, params.row.workloadName, params.row.type)}
        maxWidth={500}
        className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
        enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
      >
        {displayValue}
      </Tooltip>
    </Typography>
  );
}
