import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GetAnalyticsGraph, GetAnalyticsGraphResponse, GetAnalyticsGraphResponseParams } from "../../../../api/fetcher";
import CustomLegend from "../../../../components/CustomLegend";
import Loading from "../../../../components/Loading";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../../utils/useGetTimeoutSeconds";
import { DateType, getParsedData } from "../utils";
// import useWindowSize from "../../../../components/useWindowSize";
import ChartTooltipElement from "../../../../components/ChartTooltipElement";
import ChartTooltipTime from "../../../../components/ChartTooltipTime";
import useWindowSize from "../../../../components/useWindowSize";
import { adjustedDayjs } from "../../../../utils/dateAndTimeUtils";
import { DAY_MONTH_FORMAT, DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from "../../../../utils/formatterUtils";
import { NO_OUTLINE, TOOLTIP_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
import useFilterQueryParams from "../useFilterQueryParams";
import { ANALYTICS_PAGE_HOURLY_SYNC_ID } from "./graphUtils";

const DEFAULT_MAX_Y_AXIS_DOMAIN = 1.2;
interface CustomTooltipProps {
  active?: boolean;
  payload?: { value: string | number; name?: string; dataKey: string; stroke: string }[];
  label?: string;
  selectedChartComponents: string[];
  valueFormatter?: (tick: string) => string;
  includeHour?: boolean;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  selectedChartComponents,
  valueFormatter,
  includeHour,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        {label && (
          <ChartTooltipTime
            timestamp={label}
            timeFormat={includeHour ? DEFAULT_DATE_TIME_FORMAT : DEFAULT_DATE_FORMAT}
          />
        )}
        {payload.reverse().map((item, index) => {
          if (selectedChartComponents.includes(item.dataKey ?? "")) {
            return (
              <ChartTooltipElement
                key={index}
                color={item?.stroke ?? ""}
                value={valueFormatter ? valueFormatter(String(item.value)) : item.value}
                label={item.name}
              />
            );
          }
        })}
      </div>
    );
  }

  return null;
};

const HEIGHT = "h-[300px]";

type Element = {
  key: string;
  label: string;
  color: string;
  tooltipValueColor?: string;
  fill?: string;
  dataKey?: string;
};

interface Props {
  title: string;
  elements: Element[];
  queryParams: GetAnalyticsGraphResponseParams;
  showPercentage?: boolean;
  wrapDivClassName?: string;
  setDate: (date: DateType) => void;
  YAxisDomain?: [number | string, number | string];
  noGroupBy?: boolean;
  YAxisFormatter?: (tick: string) => string;
  isMulticluster?: boolean;
  hasLegend?: boolean;
}

const MultipleLinesChart = ({
  title,
  elements,
  queryParams,
  showPercentage,
  wrapDivClassName,
  setDate,
  YAxisDomain,
  noGroupBy,
  YAxisFormatter = (tick: string) => tick,
  isMulticluster,
  hasLegend = true,
}: Props) => {
  const { width: windowWidth } = useWindowSize();
  const { queryFn, queryKey } = GetAnalyticsGraph();
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);

  const chartComponents = Object.values(elements.map((element) => element.key));
  const [selectedChartComponents, setSelectedChartComponents] = useState<string[]>(chartComponents);

  const legendComponentStyle: { [key: string]: { color: string } } = {};

  elements.forEach((element) => {
    legendComponentStyle[element.key] = {
      color: element.color,
    };
  });

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  const filtersQueryParams = useFilterQueryParams();

  const { data, isLoading, error, isError } = useQuery<GetAnalyticsGraphResponse, Error>({
    queryKey: [queryKey, queryParams, filtersQueryParams, isMulticluster ? "multicluster" : undefined],
    queryFn: () =>
      queryFn({ ...queryParams, multiCluster: isMulticluster, timeoutSeconds: timeoutSeconds, ...filtersQueryParams }),
    enabled: isQueryEnabled,
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMulticluster });
  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  useEffect(() => {
    if (isMulticluster && !!data) {
      setIsQueryEnabled(false);
    }
  }, [data]);

  useEffect(() => {
    if (isLoading || isError) {
      setIsQueryEnabled(true);
    }
  }, [queryParams, filtersQueryParams, isLoading, isError]);

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDate({ from: from, to: to, range: "" });
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  if (isLoading) {
    return (
      <div className={HEIGHT}>
        <Loading hasTitle={false} hasFullWrapper />
      </div>
    );
  }

  if (isError) {
    console.log(error);
    return null;
  }

  const graphData = getParsedData(
    data,
    showPercentage,
    false,
    queryParams.from,
    queryParams.to,
    queryParams.groupBy,
    noGroupBy,
    true,
    queryParams.groupBy === "hour"
  );

  const maxDataPoint = Math.round(
    Math.max(
      ...graphData
        .map((item) => {
          return Object.values(item).filter((value) => typeof value === "number");
        })
        .flat()
        .map((item) => Number(item))
    ) * DEFAULT_MAX_Y_AXIS_DOMAIN
  );

  const firstXPointString = String(graphData[0]?.date);
  const firstXPointEpoch = adjustedDayjs(firstXPointString).unix() * 1000;
  let lastXPointString = graphData && String(graphData[graphData.length - 1]?.date);
  lastXPointString = lastXPointString && `${lastXPointString} GMT`;
  const lastXPointEpoch = adjustedDayjs(lastXPointString).unix() * 1000;

  return (
    <div
      className={clsx(
        wrapDivClassName,
        HEIGHT,
        "px-4 pt-8 pb-20 bg-white border border-border rounded unselectable-svg-text"
      )}
    >
      <Typography variant="body2" className="w-full text-center">
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height="100%" className="pt-[20px]">
        <ComposedChart
          syncId={ANALYTICS_PAGE_HOURLY_SYNC_ID}
          data={graphData}
          onMouseDown={(e) => {
            e.activeLabel &&
              setSelectPosition({
                ...selectPosition,
                from: adjustedDayjs(`${String(e.activeLabel)} GMT`).unix() * 1000,
                fromX: e.activeLabel,
              });
          }}
          onMouseMove={(e) => {
            selectPosition?.from &&
              setSelectPosition({
                ...selectPosition,
                to: adjustedDayjs(`${String(e.activeLabel)} GMT`).unix() * 1000,
                toX: e.activeLabel,
              });
          }}
          onMouseLeave={() => {
            if (selectPosition?.from && selectPosition?.to) {
              if (selectPosition?.from < selectPosition?.to) {
                setSelectPosition({
                  ...selectPosition,
                  to: lastXPointEpoch,
                  toX: lastXPointString,
                });
              } else {
                setSelectPosition({
                  to: selectPosition.from,
                  toX: selectPosition.fromX,
                  from: firstXPointEpoch,
                  fromX: firstXPointString,
                });
              }
            }
          }}
        >
          <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
          <Tooltip
            wrapperStyle={NO_OUTLINE}
            content={
              <CustomTooltip
                selectedChartComponents={selectedChartComponents}
                valueFormatter={YAxisFormatter}
                includeHour={queryParams.groupBy === "hour"}
              />
            }
          />
          {elements.map((element) => {
            const elementData = elements.find((e) => e.key === element.key);
            if (!elementData || !selectedChartComponents.includes(elementData.key)) return null;
            const { key, label, color, fill } = elementData;

            return (
              <Area
                type="linear"
                strokeWidth={2}
                dataKey={key}
                name={label}
                stroke={color}
                fill={fill}
                fillOpacity={fill ? 0.4 : 0}
              />
            );
          })}
          <XAxis
            dataKey="date"
            style={{ fontSize: "x-small" }}
            strokeWidth={2}
            interval={Math.floor(graphData.length / (Number(windowWidth) / 300))}
            tickFormatter={(value) => adjustedDayjs(String(value)).format(DAY_MONTH_FORMAT)}
          />
          <YAxis
            style={{ fontSize: "x-small" }}
            domain={YAxisDomain ?? [0, maxDataPoint]}
            strokeWidth={2}
            tickFormatter={(tick) => YAxisFormatter(String(tick))}
          />
          {selectPosition?.fromX && selectPosition?.toX ? (
            <ReferenceArea
              x1={selectPosition?.fromX}
              x2={selectPosition?.toX}
              stroke="#3B8BFF"
              fill="#3B8BFF"
              fillOpacity={0.3}
              strokeOpacity={0.3}
            />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
      <div className="w-full pt-4">
        {hasLegend && (
          <CustomLegend<string>
            selectedChartComponents={selectedChartComponents}
            setSelectedChartComponents={setSelectedChartComponents}
            componentStyle={legendComponentStyle}
            ChartComponents={chartComponents
              .map((component) => ({
                [component]: component,
              }))
              .reduce((a, b) => ({ ...a, ...b }), {})}
            renderNameFunction={(key) => {
              const element = elements.find((e) => e.key === key);
              return element ? element.label : "";
            }}
            className="-mt-1"
            fontWeight={500}
          />
        )}
      </div>
    </div>
  );
};

export default MultipleLinesChart;
